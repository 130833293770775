<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="dataId?'编辑项目':'添加项目'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="728px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="setCity" slot-scope="scope">
						<el-autocomplete
		                style="width: 100%"
		                class="inline-input"
		                v-model="htmlDatas[scope.current.keys]"
		                clearable
		                @clear="clearCity()"
               			@select="handleSelect"
		                :fetch-suggestions="querySearch"
		                placeholder="请选择"
		              ></el-autocomplete>
						
					</template>
						
					<template slot="tagsArrs" slot-scope="scope">
						<div style="display: inline-block;">
							<TagBind :tagValueList="htmlDatas[scope.current.keys]||[]" tagType :notAuto='true' :limit='10' :maxlength='6' addName='添加标签'/>
						</div>
					</template>
					
					<template slot="latlngs" slot-scope="scope">
						<el-input :readonly="true" v-model="htmlDatas[scope.current.keys]">
			                <el-button slot="append"  @click="openMapSelect" icon="el-icon-location"></el-button>
			            </el-input>
					</template>
					
				
				</TForm>
				
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 添加</el-button>
		        </div>
			</div>
		</el-dialog>
		<map-select
	      :position="locationObj"
	      :cityName="htmlDatas.cityName"
	      :actions="isAction"
	      :mapSelectDialog="mapSelectDialog"
	      @checkPoint='checkPoint'
	    />
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TagBind from "@/components/TagBind";
	import cityData from "@/views/business/base/project/city.json";
	import TForm from '@/components/YTable/TForm.vue';
	import MapSelect from "@/components/MapSelect";
	export default {
		props: {
			dataId: {
				type: String,
				default: '',
			},
		},
		components: {TForm,TagBind,MapSelect},
		data() {
			return {
				
				htmlDatas:{
					projectId:'',
					cityName:'',
					location:'',
	  				tagList:[],//标签
	  			},
	  			isHttping:false,
	  			formlist:[
	  				{name: '项目名称',keys: 'projectName',value: '',type: 'input',isMust:true,diyClass:'wd50',inputMethod:this.changeprojectName,maxlen:50},
	  				{name: '项目编码',keys: 'projectCode',value: '',type: 'input',isMust:true,diyClass:'wd50',trigger: ["blur", "change"]},
	  				{name: '公司',keys: 'companyId',value: '',type: 'select',options:[],isMust:true,diyClass:'wd50',},
	  				{name: '所在城市',keys: 'cityName',value: '',type: 'slot',slotName:"setCity",isMust:true,diyClass:'wd50',},
	  				{name: '占地面积',keys: 'floorSpace',value: '',type: 'input',diyClass:'wd50',},
	  				{name: '详细地址',keys: 'address',value: '',type: 'textarea'},
	  				{name:'项目标签',keys:'tagList',value:[],type:'slot',slotName:"tagsArrs"},
	  				{name:'经纬度',keys:'location',value:null,type:'slot',slotName:"latlngs"},
	  			],
	  			mapSelectDialog: false,
      			isAction: true,
      			locationObj:null,
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			
		},
		created() {
			this.initCompanyArrs();
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			checkPoint(val){
				this.locationObj={...val}
			},
			openMapSelect(){
				if (!this.htmlDatas.cityName) {
					this.$message('请选择所在城市')
		          	return;
		        }
		        if (this.htmlDatas.location != null) {
		          	this.locationObj = {
		            	lng: parseFloat(this.htmlDatas.location.split(",")[0]),
		            	lat: parseFloat(this.htmlDatas.location.split(",")[1]),
		          	};
		        } else {
		          this.locationObj = null;
		        }
		        this.isAction = true;
		        this.mapSelectDialog = true;
				
			},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
		    changeprojectName(val) {
		    	// 联动企业名称  生成编码
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.projectCode=n;
		    },
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		async initCompanyArrs(){
	  			
	  			let res = await this.ApiHttp('/organization/tenant/company/list');
	  			if(res){
	  				res.map(it=>{
						it.name = it.companyName;
						it.code = it.companyId;
					})
					this.common.insertOptions(this.formlist,'companyId',res);//添加选项数据到所属项目
	  			}
	  			
	  		},
			async getDatasById(){
				if(this.dataId){
		  			let res = await this.ApiHttp('/organization/enterprise/select/'+this.dataId);
					if(res){
						if(res.enterpriseTag){
							res.enterpriseTag=res.enterpriseTag.split(',')
						}else{
							res.enterpriseTag=[]
						}
						this.htmlDatas={...this.htmlDatas,...res}
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					...this.htmlDatas,
				}
				let res = await this.ApiHttp('/base/tenant/project/create',params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
			//城市筛选方法 -复制前辈逻辑
			querySearch(queryString, cb) {
		      let citys = cityData;
		      let results = queryString
		        ? citys.filter(this.createFilter(queryString))
		        : citys;
		      // 调用 callback 返回建议列表的数据
		      cb(results);
		    },
		    selectMapPosition(position) {
		      this.htmlDatas.location = position.lng + "," + position.lat;
		      this.mapSelectDialog = false;
		    },
	     	clearCity() {
		      	this.htmlDatas.cityName = null;
		      	this.htmlDatas.location = null;
		    },
		    handleSelect(item) {
		      	this.htmlDatas.cityName = item.value;
		      	this.htmlDatas.location = null;
		    },
		    createFilter(queryString) {
		      return (city) => {
		        return (
		          city.label.toLowerCase().indexOf(queryString.toLowerCase()) != -1
		        );
		      };
		    },
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>