var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mainBox", { attrs: { mClass: "spaceBox" } }, [
    _c(
      "div",
      { staticClass: "wapBox" },
      [
        _c("div", { staticClass: "flexs left" }, [
          _c("div", { staticClass: "l_title" }, [
            _c("div", { staticClass: "w50" }, [_vm._v("项目")]),
            _c(
              "div",
              { staticClass: "w50 evts" },
              [
                _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" } },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _c("span", { staticClass: "i_add" }, [_vm._v("+")]),
                      _vm._v(" 添加公司/项目\n\t\t\t\t\t  \t"),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "drBtn",
                                attrs: { type: "text" },
                                on: { click: _vm.toAddCompany },
                              },
                              [_vm._v("添加公司")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-dropdown-item",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "drBtn",
                                attrs: {
                                  type: "text",
                                  disabled: _vm.treeData.length < 1,
                                },
                                on: { click: _vm.toAddProject },
                              },
                              [_vm._v("添加项目")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "treeWarp" },
            [
              _c("el-tree", {
                attrs: {
                  data: _vm.treeData,
                  "expand-on-click-node": false,
                  props: _vm.treeProps,
                  "default-expand-all": "",
                },
                on: { "node-click": _vm.nodeClick },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return _c(
                        "div",
                        {
                          class: {
                            txBlue:
                              _vm.actvedNode && data.projectId == _vm.initTbPid,
                          },
                        },
                        [_vm._v(_vm._s(data.projectName))]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flexs right" }, [
          _c("div", { staticClass: "c_name" }, [
            _vm.actvedNode
              ? _c("span", [_vm._v(_vm._s(_vm.actvedNode.companyName))])
              : _vm._e(),
          ]),
          _vm.actvedNode
            ? _c("div", { staticClass: "p_name" }, [
                _vm._v(_vm._s(_vm.actvedNode.projectName)),
              ])
            : _c("div", { staticClass: "p_name" }, [_vm._v("-")]),
          _c(
            "div",
            { staticClass: "eBtns" },
            [
              _c(
                "el-button",
                {
                  staticClass: "b_it",
                  attrs: {
                    type: "primary",
                    disabled: !_vm.actvedNode,
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.toAddPosition },
                },
                [_vm._v("添加位置")]
              ),
              _c(
                "el-button",
                { staticClass: "b_it", on: { click: _vm.importTemp } },
                [_vm._v("导入模版")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "b_it",
                  on: {
                    click: function ($event) {
                      _vm.uploadModel = true
                    },
                  },
                },
                [_vm._v("导入")]
              ),
              _c(
                "el-button",
                { staticClass: "b_it", on: { click: _vm.exportPosition } },
                [_vm._v("导出")]
              ),
              _c(
                "el-button",
                { staticClass: "b_it", on: { click: _vm.openPrintModal } },
                [_vm._v("下载位置二维码")]
              ),
              _c(
                "el-button",
                { staticClass: "b_it", on: { click: _vm.refreshTable } },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "b_it",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.showRowQuery.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("筛选")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "tbBox" }, [
            _c("div", { staticClass: "mmTtop" }, [
              _c("div", { staticClass: "n_lf" }, [_vm._v("位置名称   ")]),
              _c("div", { staticClass: "n_rt" }, [_vm._v("操作")]),
            ]),
            _c(
              "div",
              { staticClass: "diyCkWarp" },
              [
                _c("el-checkbox", {
                  on: { change: _vm.checkAll },
                  model: {
                    value: _vm.isTbAll,
                    callback: function ($$v) {
                      _vm.isTbAll = $$v
                    },
                    expression: "isTbAll",
                  },
                }),
              ],
              1
            ),
            _vm.tableData.length < 0
              ? _c("div", { staticClass: "notArrs" }, [_vm._v(" 暂无数据 ")])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "tbTreeWarp",
                class: { tbTreeWarp2: _vm.rowQuery },
              },
              [
                _c("el-tree", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tbloading,
                      expression: "tbloading",
                    },
                  ],
                  ref: "tbTree",
                  staticClass: "diyTbTree",
                  attrs: {
                    "default-checked-keys": _vm.dfChecks,
                    data: _vm.tableData,
                    "show-checkbox": "",
                    "node-key": "positionId",
                    props: _vm.tbProps,
                  },
                  on: { check: _vm.checkChange },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ node, data }) {
                        return _c("div", { staticClass: "treeNode" }, [
                          _c("span", { staticClass: "n_name" }, [
                            _vm._v(_vm._s(node.label)),
                          ]),
                          _c(
                            "span",
                            { staticClass: "n_btns" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "txBlue",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toEditPosition(data)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "txBlue",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAddPosition(data)
                                    },
                                  },
                                },
                                [_vm._v("添加位置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "txBlue",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delPosition(data)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ])
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm.proInfo.open
          ? _c("AddEditProject", {
              attrs: { isOpen: _vm.proInfo.open, dataId: _vm.proInfo.dataId },
              on: { close: _vm.closeAddProject },
            })
          : _vm._e(),
        _c("AddCompany", {
          attrs: { isOpen: _vm.compInfo.open, dataId: _vm.compInfo.dataId },
          on: { close: _vm.closeAddCompany },
        }),
        _vm.ptionInfo.open
          ? _c("AddPosition", {
              attrs: {
                isOpen: _vm.ptionInfo.open,
                dataId: _vm.ptionInfo.dataId,
                parentData: _vm.operateRow,
              },
              on: { close: _vm.closeAddPosition },
            })
          : _vm._e(),
        _c("TPrintCodes", {
          attrs: { isOpen: _vm.printInfo.open, datas: _vm.printInfo.arrs },
          on: {
            close: function ($event) {
              _vm.printInfo.open = false
            },
          },
        }),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.uploadModel, width: "40%" },
            on: {
              "update:visible": function ($event) {
                _vm.uploadModel = $event
              },
            },
          },
          [
            _c(
              "template",
              { slot: "title" },
              [
                _c("title-icon"),
                _vm._v(_vm._s(_vm.$t("commons.uploadFile")) + "\n\t      \t"),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c("upload", {
                  attrs: {
                    ulType: _vm.uploadType,
                    excelType: _vm.excelType,
                    rootPath: _vm.rootPath,
                  },
                  on: { success: _vm.uploadSuccess, fail: _vm.uploadFail },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }